import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store';
import LocationC from './Location.vue'

const Location = class {
  constructor(props) {
    this.location = new Vue({
      vuetify,
      store,
      ...LocationC,
      propsData: props || {}
    }).$mount()
  }

  get() {
    return this.location.$el
  }

  getItem() {
    return this.location.getItem()
  }

  hasErrors() {
    return this.location.hasErrors()
  }
}

export default Location
