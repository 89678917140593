<template>
  <v-row class="justify-center no-gutters full-height">
    <v-col
      class="pa-4"
      cols="12"
      md="8"
    >
      <v-card>
        <div class="pa-2">
          <v-text-field
            flat
            label="Buscar"
            hide-details
            v-model="search"
            append-icon="mdi-magnify"
          ></v-text-field>
        </div>
      </v-card>
      <v-card class="mt-2">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="mt-2 pb-2">
              <div class="data-title">Nombre</div>
              <div>Cosme Miguel Lopez Camacho</div>
            </div>
            <div class="mt-2 pb-2">
                <div class="data-title">Domicilio</div>
                <div @click="find('Salvador Diaz Miron, 3023, Finisterra')">Salvador Diaz Miron</div>
            </div>
            <div class="mt-2 pb-2 d-flex justify-md-space-between">
              <div>
                  <div class="data-title">Fecha de Nacimiento</div>
                  <div>20/09/1994</div>
              </div>
              <div>
                  <div class="data-title">Vigencia de Licencia</div>
                  <div>20/09/1994</div>
              </div>
              <div>
                  <div class="data-title">Mandamientos</div>
                  <div>---------</div>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import ApiService from '@/util/api.service'
import Dialog from '@/components/dialog'
import Location from '@/components/location'

export default {
  props: {
  },
  components: {
  },
  data() {
    return {
      loading: false,
      search: ''
    }
  },
  mounted() {
  },
  methods: {
    find(val) {
      const location = new Location({
        position: { address: val },
        type: 'geocode',
        readonly: true
      })
      const dialog = new Dialog({
        title: this.label,
        size: 'large',
        height: '900px',
        actions: [{
          icon: 'mdi-check',
          color: 'success',
          help: 'Aceptar',
          condition: () => false,
          action: () => {
            if (location.hasErrors()) {
              return
            }
            this.value = location.getItem()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(location)
    }
  }
}
</script>

<style lang="scss" scoped>
  .data-title {
    font-size: .6rem;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
</style>
